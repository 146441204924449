import Vue from 'vue'
import VueRouter from 'vue-router'
import DoctorSchedule from '../components/DoctorSchedule.vue'
import Home from '../views/Home.vue'
import Doctor from '../views/Doctor.vue'

Vue.use(VueRouter)

Vue.prototype.basePath = 'https://backend.nebolitonline.ru/';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    props: true,

  },
  {
    path: '/doctor-schedule/',
    name: 'doctorSchedule',
    component: DoctorSchedule,
    props: true,

  },

  {
    path: '/doctor/:uid',
    name: 'doctor',
    component: Doctor,
    props: true,
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
