import Axios from 'axios'

export default {

	state : {
		shedule: [],
		loading: false,
	},
	getters: {
		shedule (state) {
			return state.shedule.data
		},
		loading (state) {
			return state.loading
		},
		doctorById (state) {
			return doctorId => {
				return state.shedule.data.find(sh => sh.id === doctorId)
			}
		},
		getDoctorDays (state) {
			return doctorId => {
				let object = state.shedule.data.find(sh => sh.id === doctorId)
				return object.schedule
			}
		},
		getTimesOfCurrentDay (state) {
			return doctorId => {
				let object = state.shedule.data.find(sh => sh.id === doctorId)
				let schedule = object.schedule.find(el => el.checked === true)
				return schedule.times
			}
		},
		getCheckedDay (state) {
			return doctorId => {
				let object = state.shedule.data.find(sh => sh.id === doctorId)
				return object.schedule.find(el => el.checked === true)
			}
		},
		getCheckedTime (state) {
			return doctorId => {
				let object = state.shedule.data.find(sh => sh.id === doctorId)
				let schedule = object.schedule.find(el => el.checked === true)
				return schedule.times.find(tm => tm.checked === true)
			}
		}
	},
	mutations: {
		loadShedule (state, payload) {
			state.shedule = payload
		},
		loading (state, payload) {
			state.loading = payload
		},
		setLoading (state, payload) {
			state.loading = payload
		},
		checkDay(state, object) {
			if (!object.checked) {
				object.checked = true
			}
		},
		checkTime(state, object) {
			if (!object.checked) {
				object.checked = true
			}
		},
		cleanChoosen(state, object) {
			if (object) {
				object.schedule.forEach(element => element.checked = false)
			}
		},
		cleanChoosenTime(state, object) {
			if (object) {
				object.forEach(element => element.checked = false)
			}
		},
	},
	actions: {

		async getShedule ({commit}) {
			let resultShedule = await Axios.get('https://backend.nebolitonline.ru/api/v1/getAllInfo/')
			commit('loadShedule', resultShedule)
		},
		async getLoading ({commit}) {
			let res = true
			commit('loading', res)
		},
		async setLoading ({commit}, payload) {
			commit('setLoading', payload)
		},
		async checkDay ({commit}, object) {
			commit('checkDay', object)
		},

		async checkTime ({commit}, object) {
			commit('checkTime', object)
		},
		async cleanChoosen ({commit}, object) {
			commit('cleanChoosen', object)
		},
		async cleanChoosenTime ({commit}, object) {
			commit('cleanChoosenTime', object)
		},
	}
}