import Axios from 'axios'

export default {
    state: {
        doctor: [],
		docloading: false,
    },
    getters: {
        doctor (state) {
            return state.doctor
        },
		docloading (state) {
			return state.docloading
		},
        getDocDays (state) {
			return state.doctor.schedule
		},
        getDoctorCheckedDay (state) {
			return state.doctor.schedule.find(el => el.checked === true)
		},
        getDoctorCheckedTime (state) {
            let checkDay = state.doctor.schedule.find(el => el.checked === true)
			return checkDay.times.find(tm => tm.checked === true)
		},
        getDocTimesOfCurrentDay (state) {
            let checkedDay = state.doctor.schedule.find(el => el.checked === true)
			return checkedDay.times
		},
    },
    mutations: {
        loadDoctor (state, payload) {
			state.doctor = payload
		},
		docloading (state, payload) {
			state.docloading = payload
		},
		setDocLoading (state, payload) {
			state.docloading = payload
		},
        checkDocDay(state, object) {
			if (!object.checked) {
				object.checked = true
			}
		},
		checkDocTime(state, object) {
			if (!object.checked) {
				object.checked = true
			}
		},
		cleanDocChoosen(state) {
			state.doctor.schedule.forEach(element => element.checked = false)
		},
		cleanDocChoosenTime(state, object) {
			if (object) {
				object.forEach(element => element.checked = false)
			}
		},
    },
    actions: {
        async getDoctor ({commit}, uid) {
            let resultDoctor = await Axios.get(`https://backend.nebolitonline.ru/api/v1/getDoctorInfo/${uid}/`)
			commit('loadDoctor', resultDoctor.data)
        },
		async getDocLoading ({commit}) {
			let res = true
			commit('docloading', res)
		},
		async setDocLoading ({commit}, payload) {
			commit('setDocLoading', payload)
		},
        async checkDocDay ({commit}, object) {
			commit('checkDocDay', object)
		},
		async checkDocTime ({commit}, object) {
			commit('checkDocTime', object)
		},
		async cleanDocChoosen ({commit}) {
			commit('cleanDocChoosen')
		},
		async cleanDocChoosenTime ({commit}, object) {
			commit('cleanDocChoosenTime', object)
		},
    }

}