<template>
	<div>
		<section>
			<div v-if="erroredShedule">
				<div class="container pt-2">
					<div class="row">
						<div class="col-12">
							<p>К сожалению сервер не доступен. Попробуйте позже.</p>
						</div>
					</div>
				</div>	
			</div>
			<div v-else>
				<div class="container">
					<div class="row" v-if="loadingShedule">
						<div class="col-12" style="padding-top: 20%;">
							<div class="spinner-border text-info" role="status">
							</div>
						</div>
					</div>
					<div class="row ml-3 mt-3" v-else>
						<div class="as-card-doctor col-lg-12 col-md-12 col-12 row pb-2 mb-3 focused">
							<div class="as-doctor-header col-lg-12 mt-2">
								<span class="doctor-name">Важная информация:</span>
								<br>- Дети до 15 лет на прием к врачам допускаются только в сопровождении родителя (законного представителя), либо с нотариально заверенным заявлением об эмансипации.
								<br>- Дети старше 15 лет на первый прием в нашей клинике должны приходить вместе с родителями (законными представителями) для подписания договора оказания медицинских услуг. Повторные приемы дети могут посещать самостоятельно.
							</div>
						</div>	
						<div v-for="doctor in shedule" :key="doctor.id" :id="doctor.id" class="as-card-doctor col-lg-12 col-md-12 col-12 row pb-2 mb-3 focused">
							<div class="as-doctor-header col-lg-6 mt-2">
								<h4 class="as-doctor-post text-left"> {{ doctor.post }}</h4>
								<p class="text-left doctor-name">{{ doctor.name }}</p>
								<p class="text-left age-limit">Врач принимает пациентов {{ doctor.restriction }}+</p>
								<p class="text-left"> cтоимость приема: от <span style="font-weight: 700;">{{ doctor.price }}</span> &#8381;</p>
							</div>
							<div class="doctor-shedule col-lg-6 mt-2">
								<Days :object=doctor></Days>
							</div>
						</div>	
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>

	import Days from './Days.vue'

	export default {
		name: 'DoctorList',
		components: {
			Days,
		},
		data () {
			return {
				erroredShedule: false,
				publicPath: process.env.BASE_URL,
			}
		},
		methods: {
			getShedule: function() {
				let shedule = this.$store.getters.shedule
				return shedule
			},
			getLoading: function() {
				return this.$store.getters.loading
			},
			setErrored: function (data) {
				this.erroredShedule = data
			},
		},
		computed: {
			shedule: function() {
				let shedule = this.getShedule()
				return shedule
			},
			loadingShedule: function() {
				return this.getLoading()
			},
		},

		filters: {
			dateFilter (value) {
				if (!value) return ''
				value = value.toString()
				return value.slice(6, 8)
			},
		}
	}
</script>