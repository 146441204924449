<template>
	<div>
		<div class="current-day row justify-content-start">
			<div 
				class="as-time-block col-md-2 col-sm-2 col-4"
				v-for="time in times" :key="time.id"
			>
				<div 
					class="as-shedule-time"
					:class="{ vacant : !time.flag , checkedTime : time.checked}"
					@click="cleanChoosen(); chooseTime(time)"
				>	
				<div v-if="!time.flag">
					<a href="#" 
						onclick="event.preventDefault()"
					>
					{{ time.date | timeFilter }}
					</a>
				</div>
				<div v-else>
					<span style="cursor: default;">{{ time.date | timeFilter }}</span>
				</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	export default {
		name: 'DoctorTimes',
		props: ['day', 'doctor', ],
		data () {
			return {
			}
		},
		methods: {
			chooseTime (object) {
				if (!object.flag) {
					this.$store.dispatch('checkTime', object)
					this.currentTime = object
				}
			},
			cleanChoosen () {
				this.$store.dispatch('cleanChoosenTime', this.getTimes())
			},
			getTimes: function() {
				return this.$store.getters.getTimesOfCurrentDay(this.doctor.id)
			},
			
		},
		computed: {
			times () {
				let times = this.getTimes()
				return times
			},
		},
		filters: {
			timeFilter (value) {
				if (!value) return ''
				value = value.toString().slice(8,12)
				return value.slice(0, 2) + ':' + value.slice(2, 4)
			},
		}
	}
</script>