<template>
    <div>
		<section v-if="appointmentSucces">
			<div class="sticky-top as-top mt-1">
				<h3 class="p-1">Ваша заявка принята. <br> Спасибо что обратились в нашу клинику!</h3>
			</div>
			<div  class="container pt-3">
				<div class="row justify-content-center">
					<div class="col-lg-5 col-md-12 shadow pb-4" style="background-color: #f4f6f7;">
						<div class="pt-3" align="left">
							Прием к врачу:
						</div>
						<div class="pt-3 doctor-info" align="left">
							{{ doctor.name }}
						</div>
						<div class="pt-3" align="left">
							Специальность:
						</div>
						<div class="pt-3 doctor-info" align="left">
							{{ doctor.post }}
						</div>
						<div class="pt-3" align="left">
							Адрес:
						</div>
						<div class="pt-3 doctor-info" align="left">
							Белгород, ул. 3-го Интернационала, д. 92
						</div>
						<div class="pt-3" align="left">
							Дата приёма:  
							<span v-if="dayObject" class="doctor-info"> {{ dayObject.date | dateFilter }} </span> 
						</div>
						<div class="pt-3 pb-3" align="left">
							Время приёма:  
							<span class="doctor-info"> {{ time.date | timeFilter }}</span>
						</div>
						<div class="pt-2" align="center" style="color: red; font-weight: 700;">
							Ожидайте, пожалуйста, СМС с подтверждением записи. <br> Если оно не поступит в течение 2-х минут, обязательно свяжитесь с клиникой по номеру: <br>
							<a style="font-weight: 700; font-size: 20px;" href="tel:+74722330170"><span class="badge badge-dark">+7 (4722) 330-170</span></a>
						</div>

						<div class="row pt-3">
							<div class="col-6">
								<button class="btn btn-primary " @click="show_nebolit">На главную</button>
							</div>
							<div class="col-6">
								<button class="btn btn-secondary " @click="show_index">Все врачи</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section v-else>
			<section  v-if="errored">
				<div  class="container pt-3">
					<div class="row mt-3 justify-content-center">
						<div class="col-lg-5 col-md-12 col-11 shadow pb-4" style="background-color: #f4f6f7;">
							<div class="pt-2" align="center" style="color: red; font-weight: 500;">
								К сожалению, запись не прошла! <br> Повторите попытку через пару минут или сразу позвоните нам по телефону:<br>
								<a style="font-weight: 700; font-size: 20px;" href="tel:+74722330170"><span class="badge badge-dark">+7 (4722) 330-170</span></a>
							</div>
							<div class="pt-2" align="center" style="font-size: 12px; padding-left: 0; padding-right: 0;">
								с 8:00 до 18:00 в будни, с 9:00 до 13:00 в субботу
							</div>
							<div class="row pt-3">
								<div class="col-6">
									<button class="btn btn-primary " @click="show_nebolit">На главную</button>
								</div>
								<div class="col-6">
									<button class="btn btn-secondary " @click="show_index">Все врачи</button>
								</div>
							</div>
						</div>
					</div>
				</div>	
			</section>
			<section v-else>
				<div class="container">
					<div class="row" v-if="docLoading">
						<div class="col-12" style="padding-top: 20%;">
							<div class="spinner-border text-info" role="status">
							</div>
						</div>
					</div>
					<div v-else>
					<div class="row ml-3 mt-3 justify-content-between">
						<div class="as-card-doctor col-lg-6 col-md-12 col-12 row pb-2 mb-3 focused">
							<div class="as-doctor-header col-lg-12 mt-2">
								<h4 class="as-doctor-post text-left">{{ doctor.post }}</h4>
								<p class="text-left doctor-name">{{ doctor.name }}</p>
								<p class="text-left age-limit">Врач принимает пациентов {{ doctor.restriction }}+</p>
							</div>	
							<div class="doctor-shedule col-lg-12 mt-2">
								<DocDays></DocDays>
							</div>
						</div>
						<div class="as-card-doctor col-lg-6 col-md-12 col-12 row pb-2 mb-3" style="background-color: #f4f6f7;">
							<div class="col-12 pt-3" align="left">
								Вы выбрали:
							</div>
							<div class="col-12" align="left">
								Дата приема:  
								<span class="doctor-info">{{ dayObject.date | dateFilter }} </span>
								<span class=""> ({{ dayObject.named | weekdayFilter }})</span>
							</div>

							<div class="col-12" align="left">
								Время приёма: 
								<span class="doctor-info" v-if="time">{{ time.date | timeFilter }}</span>
								<span class="doctor-info" v-else >Не выбрано</span>
							</div>
							<div class="col-12" align="left">
								Адрес: 
								<span class="doctor-info">ул. 3-го Интернационала, д. 92</span>
							</div>
							<div class="col-12" align="left">
								Стоимость приёма: от 
								<span style="font-weight: 600;">{{ doctor.price }}</span> &#8381;
							</div>
						</div>
							
					</div>
					<div class="row ml-3  mb-3 justify-content-between">
						<div class="as-card-doctor col-lg-6 col-md-12 col-12 row focused">
							<form id="customerForm" class="row col-12 justify-content-between">
								<input hidden type="text" id="time" name="time" v-model="time">
								<div class="col-md-12 pt-3 form-group as-group-item as-text-left">
									<span v-if="timeError">{{ timeError }}</span>
									<span v-if="errors">* {{ errors }}</span>
								</div>
								<div class="col-md-6 form-group as-group-item as-text-left"
										:class="{ 'pt-3' : !errors }">
									<label for="name">* Имя</label>
									<input
										@input="checkName"
										type="text"
										id="name"
										name="name"
										v-model="name"
										class="form-control"
										:class="{ erroredField : nameError }"
									>
									<span v-if="nameError">* {{ nameError }}</span> 
								</div>
								<div class="col-md-6 form-group as-group-item as-text-left"
									:class="{ 'pt-3' : !errors }">
									<label for="surname">* Фамилия</label>
									<input
										@input="checkSurname"
										type="text"
										id="surname"
										name="surname"
										v-model="surname"
										class="form-control"
										:class="{ erroredField : surnameError }"
									>
									<span v-if="surnameError">* {{ surnameError }}</span> 
								</div>
								<div class="col-md-6 form-group as-group-item as-text-left">
									<label for="date">* Дата рождения</label>
									<input 
										type="date"
										v-model="date"
										@input="checkDate"
										id="date" 
										name="date"
										class="form-control"
										:class="{ erroredField : dateError }"
									>
									<span v-if="dateError">* {{ dateError }}</span>
								</div>
								<div class="col-md-6 form-group as-group-item as-text-left">
									<label for="phone">* Телефон <span v-if="telChecked" style="color: black; font-weight: 700;"> подтвержден</span></label>
									<div class="input-group mb-2">
										<div class="input-group-prepend">
											<div 
												class="input-group-text"
												:style="[ telError ? { background : '#fff1f0' } : { background : '#e5ffe8' } ]"
											>+7</div>
										</div>
										<input 
											v-model="phone"
											@input="checkPhone"
											name="phone"
											id="phone"
											placeholder="(555) 555-5555"
											autocomplete="tel"
											type="tel"
											maxlength="14"
											class="form-control"
											pattern="[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}"
											:class="{ erroredField : telError }"
											v-phone
										>
									</div>
									<span v-if="telError">* {{ telError }}</span>
								</div>
								<div class="col-md-12 form-group as-text-right" v-if="telChecked">
									<div  v-if="responseLoading">
										<button form="testForm" class="btn btn-secondary">
											<div class="spinner-border text-light spinner-border-sm ml-4 mr-4" role="status">
											</div>
										</button>
									</div>
									<div v-else>
										<button form="testForm" class="btn btn-secondary" @click="submitForm">Записаться</button>
									</div>
								</div>
								<div class="col-md-12 form-group as-text-right" v-else>
									<div v-if="telCheckingStatus === 'sended' ">
										<div class="row">

											<div class="form-group col-md-7 col-12 as-text-left">
												<input 
												type="text"
												v-model="clientInputCode"
												@input="checkInputCode"
												class="form-control"
												placeholder="Введите код из смс..."
												
												name="code"
												maxlength="4"
												>
												<span v-if="clientInputError" style="color: red;">{{ clientInputError }}</span>
											</div>
											
											<div class="form-group col-md-5 col-12">
												<button class="btn btn-secondary" @click="checkClientPhone">Подтвердить</button>
											</div>
											<div class="form-group col-md-12 col-12" align="left" v-if="clientInputCodeError">
												<p>Неверный проверочный код, попробуйте еще раз</p>
											</div>
										</div>
									</div>
									<div v-else-if="telCheckingStatus === 'loading' ">
										<button form="testForm" class="btn btn-secondary" disabled>
											<div class="spinner-border text-light spinner-border-sm ml-4 mr-4" role="status">
											</div>
										</button>
									</div>
									<div v-else>
										<button class="btn btn-secondary" @click="sendPhoneCheck">Подтвердите телефон</button>
									</div>
								</div>
							</form>
						</div>
						<div class="pt-2 col-12 row" align="left">
							<div class="col-md-12 col-lg-6" style="font-size: 12px; padding-left: 0; padding-right: 0;">
								* Подтверждая телефон, Вы даете согласие на обработку своих персональных данных.
							</div>
						</div>
					</div>
					</div>
				</div>
			</section>
		</section>
	</div>
</template>

<script>
    import axios from 'axios'
    import DocDays from './DocDays.vue'
    export default {
        name: 'Doc',
        components: {
			DocDays,
		},
        data () {
            return {
                // loading: false,
				errored: false,
				publicPath: process.env.BASE_URL,

                timeError: '',
				// проверка телефона
				errors: '',
				nameError: '',
				name: '',
				surnameError: '',
				surname: '',
				dateError: '',
				date: '',
				telError: '',
				phone: '',

                telChecked: false,
				telCheckingStatus: '',
				clientInputCode: '',
				clientInputError: false,
				clientInputCodeError: false,
				appointmentSucces: false,
				responseLoading: '',
            }
        },
        methods: {
           show_index: function() {
				this.$router.push('/');
			},
			show_nebolit: function() {
				document.location.href = "https://nebolitonline.ru/";
			},

			checkName: function () {
				this.nameError = '';

				if (!this.name) {

					this.nameError = 'Заполните это поле';
					
				}

			},
			checkSurname: function () {
				this.surnameError = '';

				if (!this.surname) {
					this.surnameError = 'Заполните это поле';
				}

			},
			checkDate: function () {
				this.dateError = '';

				if (!this.date) {
					this.dateError = 'Заполните это поле';
				}

			},
			checkPhone: function () {
				this.telError = '';
				
				if (!this.phone) {
					this.telError = 'Заполните это поле';
				}

			},
			checkInputCode: function () {
				this.clientInputError = '';

				if (!this.clientInputCode) {
					this.clientInputError = 'Заполните это поле';
				}

			},
			checkForm (event) {

				this.errors = '';

				if (!this.name || !this.surname || !this.phone || !this.date ) {
					this.errors = 'Заполните обязательные поля!';
					event.preventDefault();
				} else if (!this.checkedTime) {
					this.timeError = 'Выберите время приема!';
					event.preventDefault();
				}

			},
			sendPhoneCheck: async function (event) {
				event.preventDefault();
				this.checkDate();
				this.checkName();
				this.checkSurname();
				this.checkPhone();

				if (!this.phone || !this.name || !this.surname || !this.phone || !this.date) {
					this.errors = 'Заполните обязательные поля!';
				} else if (!this.time) {
					this.timeError = 'Выберите время приема!';
				} else if (this.phone.length<=13) {
					this.errors = 'Неверный формат телефона';
				} else {

					this.telCheckingStatus = 'loading';
					
					let response = await axios.get(this.basePath + `/api/v1/send-phone-to-check/?phone=${this.phone}`)

					if (response.data == '200') {
						this.telCheckingStatus = 'sended';
					} else if (response.data == '500') {
						this.errored = true;
					}
				}

			},
			checkClientPhone: async function (event) {

				event.preventDefault();

				this.checkPhone();
				this.checkInputCode();


				if (this.clientInputCode) {

					this.telCheckingStatus = 'loading';

					let response = await axios.get(this.basePath + `/api/v1/check-phone/?code=${this.clientInputCode}`+`&phone=${this.phone}`)

					if (response.data == '200') {

						this.telCheckingStatus = 'checking';

						this.telChecked = true;

					} else 
					if (response.data == '500') {

						this.telCheckingStatus = 'sended';
						this.clientInputCodeError = true;
						this.clientInputCode = '';

					}

				}

			},

			submitForm : async function(event){
				event.preventDefault();
				this.checkDate();
				this.checkName();
				this.checkSurname();
				this.checkPhone();

				this.errors = '';
				if (!this.phone || !this.name || !this.surname || !this.phone || !this.date ) {
					this.errors = 'Заполните обязательные поля!';
				} else if (!this.time) {
					this.timeError = 'Выберите время приема!';
				} else if (this.phone.length<=13) {
					this.errors = 'Неверный формат телефона';
				} else {
					this.responseLoading = true;
					window['yaCounter49421647'].reachGoal('appointment-goal');
					let response =  await axios.get(this.basePath + `/api/v1/make-an-appointment/${this.doctor.id}/?date=${this.dayObject.date}&time=${this.time.date}&name=${this.name}&surname=${this.surname}&phone=${this.phone}&date_of_birth=${this.date}`)

					if (response.data == '200') {
						// await axios.get(this.basePath + `/api/v1/send-alert/?phone=${this.phone}&date=${this.time.date}&name=${this.name}&surname=${this.surname}&docname=${this.doctor.name}`)
						this.responseLoading = false;
						this.appointmentSucces = true;
					}
				}

			},
			getLoading: function() {
				return this.$store.getters.docloading
			},
			getDayObject: function() {
				return this.$store.getters.getDoctorCheckedDay
			},
        },

        computed: {
            doctor () {
                return this.$store.getters.doctor
            },
            dayObject () {
				return this.getDayObject()
			},
			time () {
				return this.$store.getters.getDoctorCheckedTime
			},
			docLoading: function() {
				return this.getLoading()
			},
        },

        filters: {
			timeFilter (value) {
				if (!value) return ''
				value = value.toString().slice(8,12)
				return value.slice(0, 2) + ':' + value.slice(2, 4)
			},
			dateFilter (value) {
				if (!value) return ''
				value = value.toString()
				let month = ''
				if (value.slice(4, 6) == '01') {
					month = ' января '
				} else if (value.slice(4, 6) == '02') {
					month = ' февраля '
				} else if (value.slice(4, 6) == '03') {
					month = ' марта '
				} else if (value.slice(4, 6) == '04') {
					month = ' апреля '	
				} else if (value.slice(4, 6) == '05') {
					month = ' мая '
				} else if (value.slice(4, 6) == '06') {
					month = ' июня '
				} else if (value.slice(4, 6) == '07') {
					month = ' июля '
				} else if (value.slice(4, 6) == '08') {
					month = ' августа '
				} else if (value.slice(4, 6) == '09') {
					month = ' сентября '
				} else if (value.slice(4, 6) == '10') {
					month = ' октября '
				} else if (value.slice(4, 6) == '11') {
					month = ' ноября '
				} else if (value.slice(4, 6) == '12') {
					month = ' декабря '	
				} else {
					month = ' '
				}
				return value.slice(6, 8) + month + value.slice(0, 4) + ' г.'
			},
			weekdayFilter (value) {
				if (!value) return ''
					if (value == 'ПН') {
						return 'Понедельник'
					}
					if (value == 'ВТ') {
						return 'Вторник'
					}
					if (value == 'СР') {
						return 'Среда'
					}
					if (value == 'ЧТ') {
						return 'Четверг'
					}
					if (value == 'ПТ') {
						return 'Пятница'
					}
					if (value == 'СБ') {
						return 'Суббота'
					}
					if (value == 'ВС') {
						return 'Воскресенье'
					}

			},
		},
		directives: {
			phone: {
				bind: function (el) {
					el.oninput = function(e) {
						if (!e.isTrusted) {
							return;
						}

						const x = this.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
						this.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
						el.dispatchEvent(new Event('input'));
					}
				}
			}
		}

    }

</script>