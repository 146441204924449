<template>
	<div>
		<div class="row justify-content-start mb-2">
			<div 
				v-for="day in paginate"
				:key="day.id" 
				class="as-shedule-block col-md-2 col-sm-2 col-2"
				:class="{ checked : day.checked  }"
				@click="cleanChoosen(); chooseDay(day); cleanChoosenTimes()"
				>
				<div class="shedule-day-week">{{ day.named }}</div>
				<div class="shedule-date">{{ day.date | dateFilter }}</div>
			</div>
		</div>
		<DocTimes></DocTimes>
		<div class="row justify-content-between pl-0 pr-0">
			<div class="p-2" @click="previosPage()" style="cursor: pointer;">
				<img :src="backImg" width="50">
			</div>
			<div class="p-2" @click="nextPage()" style="cursor: pointer;">
				<img :src="forwardImg" width="50">
			</div>
		</div>
	</div>
</template>


<script>
    
    import DocTimes from '@/components/DocTimes.vue'

	export default {
		name: 'DocDays',
		components: {
			DocTimes,
		},
		data () {
			return {
				publicPath: process.env.BASE_URL,
				currentPage: 1,
				itemsPerPage: 6,
				resultCount: 0,
				forwardImg: require('../assets/1.png'),
				backImg: require('../assets/2.png'),
			}
		},
		methods: {
			chooseDay (object) {
				this.$store.dispatch('checkDocDay', object)
			},
			cleanChoosen () {
				this.$store.dispatch('cleanDocChoosen', this.object)
			},
			cleanChoosenTimes () {
				for (const day of this.days) {
					this.$store.dispatch('cleanDocChoosenTime', day.times)
				}
			},
			setResultCount: function(data) {
				this.resultCount = data
			},
			setCurrentPage: function(data) {
				this.currentPage = data
			},
			nextPage: function() {
				this.currentPage +=1
				this.cleanChoosen()
				this.cleanChoosenTimes ()
				this.chooseDay(this.paginate[0])
			},
			previosPage: function() {
				if (this.currentPage > 1) {
					this.currentPage -=1
				}	
				this.cleanChoosen()
				this.cleanChoosenTimes ()
				this.chooseDay(this.paginate[0])
			},
			getDays: function() {
				return this.$store.getters.getDocDays
			}
		},
		mounted () {
		},
		computed: {
			currentDay () {
				return this.$store.getters.getDoctorCheckedDay
			},

			days () {
				return this.getDays()
			},
			totalPages: function() {
				return Math.ceil(this.resultCount / this.itemsPerPage)
			},
			paginate: function() {
				if (!this.days || this.days.length != this.days.length) {
					return
				}

				this.setResultCount(this.days.length)
				
				if (this.currentPage >= this.totalPages) {
					this.setCurrentPage(this.totalPages)
				}
				var index = this.currentPage * this.itemsPerPage - this.itemsPerPage
				return this.days.slice(index, index + this.itemsPerPage)
			}

		},
		filters: {
			dateFilter (value) {
				if (!value) return ''
				value = value.toString()
				let month = ''
				
				if (value.slice(4, 6) == '01') {
					month = ' янв '
				} else if (value.slice(4, 6) == '02') {
					month = ' фев '
				} else if (value.slice(4, 6) == '03') {
					month = ' мар '
				} else if (value.slice(4, 6) == '04') {
					month = ' апр '	
				} else if (value.slice(4, 6) == '05') {
					month = ' мая '
				} else if (value.slice(4, 6) == '06') {
					month = ' июн '
				} else if (value.slice(4, 6) == '07') {
					month = ' июл '
				} else if (value.slice(4, 6) == '08') {
					month = ' авг '
				} else if (value.slice(4, 6) == '09') {
					month = ' сен '
				} else if (value.slice(4, 6) == '10') {
					month = ' окт '
				} else if (value.slice(4, 6) == '11') {
					month = ' ноя '
				} else if (value.slice(4, 6) == '12') {
					month = ' дек '	
				} else {
					month = ' '
				}
 
				return value.slice(6, 8) + month
			},
		}
	}
</script>

