<template>
  <div>
    <Doc/>
  </div>
</template>

<script>
import Doc from '@/components/Doc.vue'

export default {

    name: 'Doctor',
    components: {
        Doc
    },
    created () {
        this.uploadDoctor()
    },
    methods: {
        uploadDoctor: function() {
            this.$store.dispatch('getDocLoading')
            this.$store.dispatch('getDoctor', this.$attrs.uid)
            setTimeout(() => this.$store.dispatch('setDocLoading', false), 2000)
        },
    }    
}
</script>
