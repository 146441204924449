import Vue from 'vue'
import Vuex from 'vuex'
import shedule from './modules/shedule'
import doctor from './modules/doctor'
// import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        shedule,
        doctor,
    }
    // plugins: [createPersistedState()]
})
