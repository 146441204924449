<template>
  <div>
    <DoctorList/>
  </div>
</template>

<script>
import DoctorList from '@/components/DoctorList.vue'

export default {
  name: 'Home',
  components: {
    DoctorList
  },
  mounted () {
			this.uploadDoctorsShedule()
	},
  methods: {
			uploadDoctorsShedule: function() {
				this.$store.dispatch('getShedule')
				this.$store.dispatch('getLoading')
        setTimeout(() => this.$store.dispatch('setLoading', false), 3000);
			},
  }    
}
</script>
